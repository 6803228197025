import React from 'react';

import { ApolloWrapper } from './apollo/ApolloWrapper';
import { AlertProvider, Provider } from './components/UI';
import { PostHogProvider } from './posthog';

/**
 * Wrapper that goes around the rest of Runway. Contains providers.
 *
 * @param {*} { element }
 */
/* eslint-disable-next-line react/prop-types */
const Wrapper = ({ element }) => (
  <PostHogProvider>
    <Provider>
      <ApolloWrapper>
        <AlertProvider>{element}</AlertProvider>
      </ApolloWrapper>
    </Provider>
  </PostHogProvider>
);

export { Wrapper };
