/*
 * Copyright (C) 2021 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import {
  PostHogProvider as PostHogProviderBase,
  usePostHog,
} from 'posthog-js/react';
import React, { useEffect } from 'react';

import { POSTHOG_API_KEY } from './utils/constants';

/** Register "super properties" with PostHog. */
const PostHogRegistrar: React.FC = () => {
  const posthog = usePostHog();

  useEffect(() => {
    posthog.register({
      $app_name: 'Runway',
      // FIXME: after addressing #504
      $app_version: undefined,
    });
  }, [posthog]);

  return null;
};

/** Provide a PostHog context to the rest of the app. */
const PostHogProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  return (
    <PostHogProviderBase
      apiKey={POSTHOG_API_KEY}
      options={{ api_host: 'https://app.posthog.com' }}
    >
      <PostHogRegistrar />
      {children}
    </PostHogProviderBase>
  );
};

export { PostHogProvider };
